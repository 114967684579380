import React , {Component} from 'react'
import Layout from '../layouts/index'
import DetailHeader from '../components/detailHeader/detailHeader'
import ServicesListing from '../components/services/services-listing'
import Seo from "../components/seo/seo"

class Services extends Component {
    render() {
        const url = this.props.location.href;
        return (
            <Layout>
                <Seo title="Services" url={url}/>
                <DetailHeader title="Nos services"/>
                <ServicesListing/>
            </Layout>
        );
    }
}

export default Services;
